import React from 'react';
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
  HatenaShareButton,
  HatenaIcon
} from 'react-share';
import styles from './Share.module.scss'

const config = {
  size: 36
}

type Props = {
  title: string,
  size?: number,
};

const Share = ({ title, size }: Props) => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <div className={styles['share']}>
        <h2 className={styles['share__title']}>SHARE</h2>
        <div>
            <TwitterShareButton className={styles['share__button']} url={url} title={title}>
                <TwitterIcon size={size ? size : config.size} round />
            </TwitterShareButton>
            <FacebookShareButton className={styles['share__button']} url={url}>
                <FacebookIcon size={size ? size : config.size} round />
            </FacebookShareButton>
            <LineShareButton className={styles['share__button']} url={url}>
                <LineIcon size={size ? size : config.size} round />
            </LineShareButton>
            <HatenaShareButton className={styles['share__button']} url={url}>
                <HatenaIcon size={size ? size : config.size} round />
            </HatenaShareButton>
        </div>
    </div>
  )
};

export default Share;