// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import { Box, Divider, Grid } from '@chakra-ui/react';
import { Katex } from 'katex/dist/katex.min.css';
import { Disqus } from 'gatsby-plugin-disqus';

import Layout from '../components/Layout';
import Post from '../components/Post';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';
// import TOC from '../components/TOC/TOC';
// import TOCDrawer from '../components/TOC/TOCDrawer';

type Props = {
  data: {
    markdownRemark: MarkdownRemark
  }
};

const PostTemplate = ({ data, pageContext }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const { frontmatter } = data.markdownRemark;
  const { title: postTitle, description: postDescription, socialImage } = frontmatter;
  const metaDescription = postDescription !== null ? postDescription : siteSubtitle;
  const Context = pageContext;

  return (
    <Layout title={`${postTitle} - ${siteTitle}`} description={metaDescription} socialImage={socialImage} >
      <Post post={data.markdownRemark} prevnext = {Context}/>
      <Disqus
      identifier={frontmatter.id}
      title={frontmatter.title}
      />
    </Layout>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        tagSlugs
      }
      frontmatter {
        date
        modifiedDate
        description
        category
        tags
        title
        socialImage
      }
      tableOfContents
    }
  }
`;

export default PostTemplate;
