'use strict';

module.exports = {
  url: 'https://otsuneko-blog.com',
  pathPrefix: '/',
  title: 'otsunekoの日常',
  subtitle: '気ままなブログ',
  copyright: '© 2021 otsunekoの日常',
  disqusShortname: '',
  postsPerPage: 5,
  googleAnalyticsId: process.env.GA_TRACKING_ID,
  useKatex: false,
  menu: [
    {
      label: 'Rating History(外部リンク)',
      path: 'https://rating-history.herokuapp.com/index.html?handle_topcoder=&handle_codeforces=otsuneko&handle_atcoder=otsuneko&handle_aoj=otsuneko&handle_yukicoder=otsuneko&handle_librarychecker='
    },
    {
      label: '競プロカレンダー(外部リンク)',
      path: 'https://competitiveprogramming.info/calendar'
    }
  ],
  author: {
    name: 'otsuneko',
    photo: '/otsuneko.jpeg',
    bio: 'ゲームと運動と温泉が好きなITエンジニア。駆け出し競プロerです。AtCoder緑 Codeforces緑 OMC茶 (いずれもHighest)',
    contacts: {
      email: '',
      facebook: '',
      telegram: '',
      twitter: 'otsuneko_kyopro',
      github: 'otsuneko',
      rss: '',
      vkontakte: '',
      linkedin: '',
      instagram: '',
      line: '',
      gitlab: '',
      weibo: '',
      codepen: '',
      youtube: '',
      soundcloud: '',
    }
  }
};
