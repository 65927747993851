// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
// import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import type { Node } from '../../types';
import type { PageContext } from '../../types';
import Share from '../Share/Share';
import RelatedPosts from '../RelatedPosts/RelatedPosts'

type Props = {
  post: Node,
  prevnext: PageContext
};

const Post = ({ post, prevnext }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { prev, next } = prevnext;
  const { title, date, modifiedDate, category, tags } = post.frontmatter;
  console.log('post.frontmatter', post.frontmatter)

  return (
    <div className={styles['post']}>
      {/* <Link className={styles['post__home-button']} to="/">記事一覧</Link> */}

      <div className={styles['post__content']}>
        <Content body={html} title={title} date={date} modifiedDate={modifiedDate}/>
      </div>

      <div className={styles['post__footer']}>
        {/* <Meta date={date} /> */}
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}

        <Share title={title} />

        <ul className={styles['post__prevnext-ul']}>
          <li className={styles['post__prevnext-li']}>
            {next !== null && next.fields.slug !== '/pages/about' && (
              <Link className={styles['post__prevnext-link']} to={`${next.fields.slug}`}>
                <p>←次の記事</p>
                <p>{next.frontmatter.title}</p>
              </Link>
            )}
          </li>
          <li className={styles['post__prevnext-li']}>
            {prev && prev.fields.slug !== '/pages/about' && (
              <Link className={styles['post__prevnext-link']} to={`${prev.fields.slug}`}>
                <p className={styles['post__p-right']}>前の記事→</p>
                <p className={styles['post__p-right']}>{prev.frontmatter.title}</p>
              </Link>
            )}
          </li>
        </ul>

        <RelatedPosts title={title} category={category}/>

        <Author className={styles['post__content']} />
      </div>

      <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
