// @flow strict
import React from 'react';
import moment from 'moment';
import styles from './Content.module.scss';

type Props = {
  body: string,
  title: string,
  date: String,
  modifiedDate?: String
};

const Content = ({ body, title, date, modifiedDate }: Props) => (
  <div className={styles['content']}>
    <h1 className={styles['content__title']}>{title}</h1>
    <div className={styles['content__date']}>公開日：{moment(date).format('YYYY年M月D日')}</div>
    {modifiedDate ? ( // modifiedDate が指定されている場合
      <div className={styles['content__date']}>最終更新日：{moment(modifiedDate).format('YYYY年M月D日')}</div>
    ) : ( // modifiedDate が指定されていない場合
      <div className={styles['content__date']}>最終更新日：{moment(date).format('YYYY年M月D日')}</div>
    )}
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
