import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
const siteConfig = require('../../../config.js');
import styles from './RelatedPosts.module.scss'

type Props = {
  category: String
};

const RelatedPosts = ({ title, category }: Props) => {
  const data = useStaticQuery(
    graphql`
      query RelatedPosts{
        allMarkdownRemark{
          edges {
            node {
              fields {
                slug
                categorySlug
              }
              frontmatter {
                title
                date
                category
                description
              }
            }
          }
        }
      }
    `
  );

  const { postsPerPage } = siteConfig; //最大何個の関連記事を表示するか

  // 同じカテゴリの関連記事を最新のものからpostsPerPage件取得
  const relatedPosts = data.allMarkdownRemark.edges.filter(
    (edge) =>
      edge.node.frontmatter.category === category &&
      edge.node.frontmatter.title !== title
  ).reverse().slice(0,postsPerPage);

  // 関連記事がない場合は表示しない
  if (!relatedPosts.length) {
    return null;
  }

  return (
    <div className={styles['related-posts']}>
        <h3 className={styles['related-posts__title']} >関連記事</h3>
        <ul className={styles['related-posts__items']}>
            {relatedPosts.map((edge) => (
                <li key={edge.node.fields.slug}>
                <Link className={styles['related-posts__article']} to={`${edge.node.fields.slug}/`}>{edge.node.frontmatter.title}</Link>
                </li>
            ))}
        </ul>
    </div>
  )
};

export default RelatedPosts;
